import * as React from "react"
import { graphql } from "gatsby"
import BlogIndex from "../components/blog-index"

export default function BlogIndexQuery(props) {
  const posts = props.data.allContentfulBlogPost.nodes

  return <BlogIndex posts={posts} />
}

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { fields: date, order: DESC }) {
      nodes {
        date
        category
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          alt
          description
        }
        slug
        title
        heroTitle
        excerpt {
          excerpt
        }
        id
        author {
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
            description
          }
          name
        }
        body {
          raw
          references {
            ...ContentfulAssetContent
          }
        }
      }
    }
  }
`
